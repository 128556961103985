import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { convertFirestoreTimestampToDate } from '../utils/date-utils';
import { useIntl } from 'react-intl';
import { FaCalendarAlt, FaMapMarkerAlt, FaRegCalendarAlt, FaTag } from 'react-icons/fa';
import AddressAutocomplete from '../components/career-page/address-autocomplete';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression, LatLngTuple } from 'leaflet';

delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface Job {
    id: string;
    title: string;
    date: string;
    category: string;
    city: string;
    link: string;
    latitude?: number;
    longitude?: number;
}

interface QueryParams {
    borderRadius?: string;
    color?: string;
    displayMode?: 'card' | 'table' | 'row';
    showMap?: 'true' | 'false';
}

const JobWidget: React.FC = () => {
    const { customerId } = useParams<{ customerId: string }>();
    const location = useLocation();
    const queryParams = queryString.parse(location.search) as QueryParams;

    const [jobs, setJobs] = useState<Job[]>([]);
    const [page, setPage] = useState<number>(0);
    const [hasMoreJobs, setHasMoreJobs] = useState<boolean>(true);

    const [lat, setLat] = useState<number>();
    const [long, setLong] = useState<number>();

    const [loading, setLoading] = useState(false);

    const handleAddressSelection = async (address: {
        fullAddress: string;
        state: string;
        postalCode: string;
        city: string;
        province: string;
        address: string;
    }) => {
        try {
            setLoading(true);

            // Construct the full address to pass into Nominatim API
            const fullAddress = `${address.address}, ${address.city}, ${address.province}, ${address.state}, ${address.postalCode}`;

            // Fetch lat and long using Nominatim API
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(fullAddress)}`
            );
            const data = await response.json();

            if (data && data.length > 0) {
                const latitude = parseFloat(data[0].lat);
                const longitude = parseFloat(data[0].lon);

                // Set the latitude and longitude in the state
                setLat(latitude);
                setLong(longitude);
            }

        } catch (error) {
            console.error('Error fetching lat/lon from OpenStreetMap', error);
        } finally {
            setLoading(false);
        }
    };

    interface Filters {
        dateRange: (Date | null)[];
        keywords: string | null;
    }
    const [filters, setFilters] = useState<Filters>({
        dateRange: [null, null],
        keywords: null,
    });

    const intl = useIntl();
    const apiUrl = `${process.env.REACT_APP_API_URL}/getJobPostsByCompanyId`;
    const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;
    const borderRadius = queryParams.borderRadius || '0px';
    const backgroundColor = queryParams.color || '#f9f9f9';
    const displayMode = queryParams.displayMode || 'card';
    const showMap = queryParams.showMap === 'true';

    const fetchJobs = async (reset = false) => {
        const [startDate, endDate] = filters.dateRange;
        const startSeconds = startDate ? Math.floor(startDate.getTime() / 1000) : "";
        const endSeconds = endDate ? Math.floor(endDate.getTime() / 1000) : "";
        let latitude = lat ?? "";
        let longitude = long ?? "";

        try {
            const response = await fetch(
                `${apiUrl}?companyId=${customerId}&page=${page}&startDate=${startSeconds}&endDate=${endSeconds}&latitude=${latitude}&longitude=${longitude}&keywords=${filters.keywords || ''}`,
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            const fetchedJobs = await response.json();
            const formattedJobs = fetchedJobs.jobPosts.map((job: any) => {
                if (!job.latitude || !job.longitude) return;

                const { latitude, longitude } = { latitude: job.latitude, longitude: job.longitude };

                return {
                    ...job,
                    date: convertFirestoreTimestampToDate(job.date._seconds, job.date._nanoseconds).toLocaleDateString(),
                    latitude,
                    longitude,
                } as Job;
            });

            if (reset) {
                setJobs(formattedJobs);
                setPage(0);
            } else {
                setJobs((prevJobs) => {
                    const existingJobIds = new Set(prevJobs.map((j) => j.link));
                    const newJobs = [...prevJobs, ...formattedJobs.filter((job: { link: string }) => !existingJobIds.has(job.link))];
                    return newJobs;
                });
            }

            setHasMoreJobs(fetchedJobs.jobPosts.length > 0);

        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    const handleSubmit = () => {
        setJobs([]);
        setHasMoreJobs(true);
        setPage(0);
        fetchJobs(true);
    };

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        fetchJobs(); // Fetch the next page of jobs whenever the page changes
    }, [page]);

    const renderCard = (job: Job) => (
        <div key={job.link} className="bg-white shadow-lg overflow-hidden p-4" style={{ borderRadius }}>
            <div className="h-full flex flex-col justify-between">
                <h2 className="text-xl font-semibold text-gray-800 mb-2 break-words">{job.title}</h2>
                <div className="flex flex-col items-center">
                    <div className="text-sm text-gray-600 mb-1">
                        <div className="flex items-center mt-2">
                            <FaRegCalendarAlt className="text-gray-500 mr-2" />
                            {job.date}
                        </div>
                    </div>
                    <div className="text-sm text-gray-600 mb-3">
                        <div className="flex items-center mt-2">
                            <FaMapMarkerAlt className="text-gray-500 mr-2" />
                            {job.city}
                        </div>
                    </div>
                </div>
                <a
                    href={job.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block fill-available w-full text-center font-bold p-2 rounded-full transition duration-150 ease-in-out no-underline"
                    style={{ backgroundColor, color: '#fff' }}
                >
                    {intl.formatMessage({ id: "careerPageWidget.discoverMore", defaultMessage: "Discover More" })}
                </a>
            </div>
        </div>
    );

    const renderTable = () => (
        <div className="overflow-x-auto">
            <table className="w-full bg-white shadow-lg text-left" style={{ borderCollapse: 'separate', borderSpacing: 0, border: '1px solid #e5e7eb', borderRadius }}>
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b border-r" style={{ borderTopLeftRadius: borderRadius }}>
                            {intl.formatMessage({ id: "careerPageWidget.title", defaultMessage: "Title" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b border-r">
                            {intl.formatMessage({ id: "careerPageWidget.date", defaultMessage: "Date" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b border-r">
                            {intl.formatMessage({ id: "careerPageWidget.location", defaultMessage: "Location" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b" style={{ borderTopRightRadius: borderRadius }}>
                            {intl.formatMessage({ id: "careerPageWidget.details", defaultMessage: "Details" })}
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {jobs.map((job, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? `${backgroundColor}22` : '#ffffff' }}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-b border-r">
                                {job.title}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-r">
                                <div className="flex items-center">
                                    <FaRegCalendarAlt className="text-gray-500 mr-2" />
                                    {job.date}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-r">
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="text-gray-500 mr-2" />
                                    {job.city}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium border-b">
                                <a href={job.link} target="_blank" rel="noopener noreferrer" className="p-2 px-4 text-white rounded bg-blue-500 hover:bg-blue-600 no-underline"
                                    style={{ backgroundColor: `${backgroundColor}`, border: `2 px solid ${backgroundColor}` }}>
                                    {intl.formatMessage({ id: "careerPageWidget.discoverMore", defaultMessage: "Discover More" })}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const renderRows = () => (
        <div className="w-full">
            {jobs.map((job, index) => (
                <div
                    key={index}
                    className="flex flex-col items-center px-6 py-4 border-b cursor-pointer"
                    style={{
                        backgroundColor: index % 2 === 0 ? `${backgroundColor}10` : '#ffffff',
                    }}
                    onClick={() => window.open(job.link, "_blank")}
                >
                    {/* Job Title */}
                    <div className="text-3xl font-semibold text-gray-800 text-center mb-2"
                        style={{
                            color: `${backgroundColor}`,
                        }}>
                        {job.title}
                    </div>
                    {/* Job Info */}
                    <div className="flex flex-row items-center text-sm text-gray-600 text-center flex-wrap justify-center">
                        {job.category?.length >= 1 && (
                            <div className="flex text-stone-600 items-center mb-1 p-2">
                                <FaTag className="text-gray-500 mr-2" />
                                {job.category}
                            </div>
                        )}
                        <div className="flex text-stone-600 items-center mb-1 p-2">
                            <FaMapMarkerAlt className="text-gray-500 mr-2" />
                            {job.city}
                        </div>
                        <div className="flex text-stone-600 items-center p-2">
                            <FaCalendarAlt className="text-gray-500 mr-2" />
                            {job.date}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newDateRange = [...filters.dateRange];
        newDateRange[index] = new Date(e.target.value);
        setFilters((prev) => ({ ...prev, dateRange: newDateRange }));
    };

    // Function to calculate the bounding box and adjust the zoom level
    const calculateZoomLevel = (jobs: Job[], map: L.Map) => {
        if (jobs.length === 0) return { center: [51.505, -0.09] as LatLngTuple, zoom: 4 };

        const coordinates: LatLngExpression[] = jobs.map(job => [job.latitude!, job.longitude!] as LatLngTuple);
        const bounds = L.latLngBounds(coordinates);
        const center = bounds.getCenter();
        const zoom = Math.min(Math.max(map.getBoundsZoom(bounds) - 1, 2), 13); // Adjust zoom level based on bounding box

        return { center: [center.lat, center.lng] as LatLngTuple, zoom };
    };

    // Function to zoom into a specific job's location on the map
    const MapZoomHandler: React.FC<{ jobs: Job[] }> = ({ jobs }) => {
        const map = useMap();
        useEffect(() => {
            const { center, zoom } = calculateZoomLevel(jobs, map);
            map.setView(center, zoom);
        }, [jobs, map]);
        return null;
    };

    const renderDisplay = () => {
        if (displayMode === 'card') {
            return (
                <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4 ${!showMap ? 'lg:w-full' : ''}`}>
                    {jobs.map(renderCard)}
                </div>
            );
        } else if (displayMode === 'row') {
            return (
                <div className={`mb-4 ${!showMap ? 'lg:w-full' : ''}`}>
                    {renderRows()}
                </div>
            );
        } else {
            return (
                <div className={`${!showMap ? 'lg:w-full' : ''}`}>
                    {renderTable()}
                </div>
            );
        }
    };

    const mapRef = useRef<L.Map | null>(null);

    useEffect(() => {
        const handleResize = () => {
            if (mapRef.current) {
                mapRef.current.invalidateSize();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize();
        }
    }, [jobs]);

    const mapContainerHeight = Math.min(window.innerHeight - 200, jobs.length * 100);

    return (
        <div className="p-4">
            <div className="flex flex-col lg:flex-row justify-start items-end mb-8 gap-4">
                {/* Start Date Field */}
                <div className="flex flex-col lg:w-1/2 w-full">
                    <label className="text-start mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageWidget.startDate", defaultMessage: "Start Date:" })}</label>
                    <input
                        type="date"
                        className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => handleDateChange(e, 0)}
                    />
                </div>

                {/* End Date Field */}
                <div className="flex flex-col lg:w-1/2 w-full">
                    <label className="text-start mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageWidget.endDate", defaultMessage: "End Date:" })}</label>
                    <input
                        type="date"
                        className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => handleDateChange(e, 1)}
                    />
                </div>

                {/* Location Field */}
                <div className="flex flex-col relative lg:w-1/2 w-full">
                    <AddressAutocomplete
                        onSelectAddress={handleAddressSelection}
                        setLoading={setLoading}
                        loading={loading}
                    />
                </div>

                {/* Keywords Input Field */}
                <div className="flex flex-col lg:w-1/2 w-full">
                    <label className="text-start mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageWidget.keywords", defaultMessage: "Keywords:" })}</label>
                    <input
                        type="text"
                        className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => setFilters((prev) => ({ ...prev, keywords: e.target.value }))}
                    />
                </div>

                {/* Submit Button */}
                <div className="flex flex-col lg:w-1/2 w-full">
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-500 border-solid border border-blue-500 text-white p-2 cursor-pointer rounded-lg hover:bg-blue-600"
                        style={{ backgroundColor: `${backgroundColor}`, border: `2 px solid ${backgroundColor}` }}>
                        {intl.formatMessage({ id: "careerPageWidget.submit", defaultMessage: "Submit" })}
                    </button>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row">
                <div className={`${!showMap ? 'lg:w-full' : 'lg:w-2/3'}`}>
                    {renderDisplay()}

                    {hasMoreJobs && (
                        <button onClick={loadMore} className="my-4 bg-blue-500 border-solid border border-blue-500 text-white p-2 cursor-pointer rounded-lg hover:bg-blue-600"
                            style={{ backgroundColor: `${backgroundColor}`, border: `${backgroundColor}` }}>
                            {intl.formatMessage({ id: "careerPageWidget.loadMore", defaultMessage: "Load More" })}
                        </button>
                    )}
                </div>

                {showMap && (<div className="lg:w-1/3 lg:ml-4 mt-4 lg:mt-0 flex-grow" style={{ height: `${mapContainerHeight}px` }}>
                    <MapContainer
                        center={[51.505, -0.09]}
                        zoom={4}
                        scrollWheelZoom={true}
                        preferCanvas={true}
                        className="h-full"
                        ref={mapRef}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {/* Markers */}
                        {jobs.map((job) => (
                            job.latitude && job.longitude && (
                                <Marker key={job.link} position={[job.latitude, job.longitude] as LatLngTuple}>
                                    <Popup>
                                        <div>
                                            <h2>{job.title}</h2>
                                            <p>{job.city}</p>
                                            <p>{job.date}</p>
                                            <a href={job.link} target="_blank" rel="noopener noreferrer">
                                                {intl.formatMessage({ id: "careerPageWidget.discoverMore", defaultMessage: "Discover More" })}
                                            </a>
                                        </div>
                                    </Popup>
                                </Marker>
                            )
                        ))}
                        <MapZoomHandler jobs={jobs} />
                    </MapContainer>
                </div>)}

            </div>
        </div>
    );

};

export default JobWidget;
